import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../models/auth/auth.service';
import { map, of, switchMap, take, tap } from 'rxjs';
import { StateService } from '../services/state.service';


export const userGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const stateService = inject(StateService);
  const router = inject(Router);
  
  return stateService.select(state => state.signedIn).pipe(
    switchMap(signedIn => {
      console.log('AUTH GUARD LOGGED IN ', signedIn);
      if (signedIn) {
        return of(true);
      } else {
        console.log('AUTH GUARD IS LOGGED IN IN AUTHSERVICE ? : ', authService.isAuthenticated())
        const isSignedIn = of(authService.isAuthenticated());
        if(!isSignedIn){
          router.navigate(['/auth/login']);
          return of(false);
        } else {
          return of(true);
        }
      }
    }),
  );
};


export const notLoggedInChild: CanActivateChildFn = (route, state) => {
  const authService = inject(AuthService);
  const stateService = inject(StateService);
  const router = inject(Router);
  
  return stateService.select(state => state.signedIn).pipe(
    switchMap(signedIn => {
      if (signedIn) {
        router.navigate(['/admin/dashboard']);
        return of(false);

      } else {
        if(authService.isAuthenticated()){
          router.navigate(['/admin/dashboard']);
          return of(false);
        } else {
          return of(true);
        }
        
      }
    }),
  );
};
