import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { notLoggedInChild, userGuard } from './shared/guards/auth.guard';

const pages = {
  landing: '',
  admin: 'admin',
  dashboard: 'dashboard',
  clinics: 'clinics',
  orders: 'orders',
  account: 'account',
  edit: 'edit',
  users: 'users',
  auth: 'auth',
  chats: 'chats',
  corrections: 'corrections',
  notFound: '404',
}

const orderPages = {
  all : 'all',
  drafts : 'drafts',
  pending : 'pending',
  inProgress : 'in-progress',
  completed : 'completed',
  canceled : 'canceled',
  toDoToday : 'to-do-today',
  toDoTommorow : 'to-do-tommorow',
  statusHistory : 'status-history',
  movedForward: 'moved-forward'
}

const UsersRoutes = {
  addNew: 'add-new',
  allUsers: 'all',
  patients: 'patients',
  doctors: 'doctors',
  technicians: 'technicians',
  couriers: 'couriers'
};

const AuthRoutes = {
  login: 'login',
  register: 'register',
  forgotPassword: 'forgot-password',
  resetPassword: 'password-reset',
  verifyEmail: 'verify-email',
  emailConfirmation: 'email-confirmation',
  authError: 'authentication-error',
  authSuccess: 'authentication-success'
};

export const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    pathMatch: 'full'
  },
  {
    path: pages.admin,
    loadComponent: () => import('./routes/dashboard/dashboard.component').then(m => m.DashboardComponent),
    canActivate: [userGuard],
    children: [
      {
        path: pages.dashboard,
        loadComponent: () => import('./routes/dashboard/routes/dashboard/dashboard.component').then(m => m.DashboardComponent),
      },
      {
        path: pages.users,
        loadComponent: () => import('./routes/dashboard/routes/users/users.component').then(m => m.UsersComponent),
        children: [
          {
            path: UsersRoutes.allUsers,
            loadComponent: () => import('./routes/dashboard/routes/users/routes/all-users/all-users.component').then(m => m.AllUsersComponent),
          },
          {
            path: UsersRoutes.addNew,
            loadComponent: () => import('./routes/dashboard/routes/users/routes/add-new/add-new.component').then(m => m.AddNewComponent),
          },
          {
            path: UsersRoutes.patients,
            loadComponent: () => import('./routes/dashboard/routes/users/routes/patients/patients.component').then(m => m.PatientsComponent),
          },
          {
            path: `${UsersRoutes.patients}/:patientId`,
            loadComponent: () => import('./routes/dashboard/routes/users/routes/patients/patient/patient.component').then(m => m.PatientComponent),
            children: [
              {
                path: `account`,
                loadComponent: () => import('./routes/dashboard/routes/users/routes/patients/patient/patient-account/patient-account.component').then(m => m.PatientAccountComponent),
              },
              {
                path: `orders`,
                loadComponent: () => import('./routes/dashboard/routes/orders/orders.component').then(m => m.OrdersComponent),
              },
            ]
          },
          {
            path: UsersRoutes.doctors,
            loadComponent: () => import('./routes/dashboard/routes/users/routes/doctors/doctors.component').then(m => m.DoctorsComponent),
          },
          {
            path: `${UsersRoutes.doctors}/:doctorId`,
            loadComponent: () => import('./routes/dashboard/routes/users/routes/doctors/doctor/doctor.component').then(m => m.DoctorComponent),
            children: [
              {
                path: `account`,
                loadComponent: () => import('./routes/dashboard/routes/users/routes/doctors/doctor/doctor-account/doctor-account.component').then(m => m.DoctorAccountComponent),
              },
              {
                path: `orders`,
                loadComponent: () => import('./routes/dashboard/routes/orders/orders.component').then(m => m.OrdersComponent),
              },
              {
                path: `clinics`,
                loadComponent: () => import('./routes/dashboard/routes/clinics/clinics.component').then(m => m.ClinicsComponent),
              },
              {
                path: `patients`,
                loadComponent: () => import('./routes/dashboard/routes/users/routes/patients/patients.component').then(m => m.PatientsComponent),
              },
              {
                path: `corrections`,
                loadComponent: () => import('./routes/dashboard/routes/returns/returns.component').then(m => m.ReturnsComponent),
              },
            ]
          },
          {
            path: UsersRoutes.technicians,
            loadComponent: () => import('./routes/dashboard/routes/users/routes/technicians/technicians.component').then(m => m.TechniciansComponent),
          },
          {
            path: `${UsersRoutes.technicians}/:technicianId`,
            loadComponent: () => import('./routes/dashboard/routes/users/routes/technicians/technician/technician.component').then(m => m.TechnicianComponent),
            children: [
              {
                path: `account`,
                loadComponent: () => import('./routes/dashboard/routes/users/routes/technicians/technician/technician-account/technician-account.component').then(m => m.TechnicianAccountComponent),
              },
              {
                path: `orders`,
                loadComponent: () => import('./routes/dashboard/routes/orders/orders.component').then(m => m.OrdersComponent),
              }
            ]
          },
          {
            path: UsersRoutes.couriers,
            loadComponent: () => import('./routes/dashboard/routes/users/routes/couriers/couriers.component').then(m => m.CouriersComponent),
          },
          {
            path: `${UsersRoutes.couriers}/:courierId`,
            loadComponent: () => import('./routes/dashboard/routes/users/routes/couriers/courier/courier.component').then(m => m.CourierComponent),
            children: [
              {
                path: `account`,
                loadComponent: () => import('./routes/dashboard/routes/users/routes/couriers/courier/courier-account/courier-account.component').then(m => m.CourierAccountComponent),
              },
              {
                path: `orders`,
                loadComponent: () => import('./routes/dashboard/routes/orders/orders.component').then(m => m.OrdersComponent),
              },
              {
                path: `clinics`,
                loadComponent: () => import('./routes/dashboard/routes/clinics/clinics.component').then(m => m.ClinicsComponent),
              },
              {
                path: `patients`,
                loadComponent: () => import('./routes/dashboard/routes/users/routes/patients/patients.component').then(m => m.PatientsComponent),
              },
            ]
          },
        ]
      },
      {
        path: pages.corrections,
        loadComponent: () => import('./routes/dashboard/routes/returns/returns.component').then(m => m.ReturnsComponent),
      },
      {
        path: pages.clinics,
        loadComponent: () => import('./routes/dashboard/routes/clinics/clinics.component').then(m => m.ClinicsComponent),
      },
      {
        path: `${pages.clinics}/:clinicId`,
        loadComponent: () => import('./routes/dashboard/routes/clinics/routes/clinic/clinic.component').then(m => m.ClinicComponent),
        children: [
          {
            path: `account`,
            loadComponent: () => import('./routes/dashboard/routes/clinics/routes/clinic/clinic-account/clinic-account.component').then(m => m.ClinicAccountComponent),
          },
          {
            path: `orders`,
            loadComponent: () => import('./routes/dashboard/routes/orders/orders.component').then(m => m.OrdersComponent),
          },
          {
            path: `patients`,
            loadComponent: () => import('./routes/dashboard/routes/users/routes/patients/patients.component').then(m => m.PatientsComponent),
          }
        ]
      },
      {
        path: pages.orders,
        loadComponent: () => import('./routes/dashboard/routes/orders/orders.component').then(m => m.OrdersComponent),
      },
        {
          path: `${pages.orders}/${orderPages.drafts}`,
          loadComponent: () => import('./routes/dashboard/routes/orders/orders.component').then(m => m.OrdersComponent),
        },
        {
          path: `${pages.orders}/${orderPages.pending}`,
          loadComponent: () => import('./routes/dashboard/routes/orders/orders.component').then(m => m.OrdersComponent),
        },
        {
          path: `${pages.orders}/${orderPages.toDoToday}`,
          loadComponent: () => import('./routes/dashboard/routes/orders/orders.component').then(m => m.OrdersComponent),
        },
        {
          path: `${pages.orders}/${orderPages.toDoTommorow}`,
          loadComponent: () => import('./routes/dashboard/routes/orders/orders.component').then(m => m.OrdersComponent),
        },
        {
          path: `${pages.orders}/${orderPages.movedForward}`,
          loadComponent: () => import('./routes/dashboard/routes/orders/orders.component').then(m => m.OrdersComponent),
        },
        {
          path: `${pages.orders}/${orderPages.inProgress}`,
          loadComponent: () => import('./routes/dashboard/routes/orders/orders.component').then(m => m.OrdersComponent),
        },
        {
          path: `${pages.orders}/${orderPages.completed}`,
          loadComponent: () => import('./routes/dashboard/routes/orders/orders.component').then(m => m.OrdersComponent),
        },
        {
          path: `${pages.orders}/${orderPages.canceled}`,
          loadComponent: () => import('./routes/dashboard/routes/orders/orders.component').then(m => m.OrdersComponent),
        },
        {
          path: `${pages.orders}/:orderId`,
          loadComponent: () => import('./routes/dashboard/routes/orders/routes/order/order.component').then(m => m.OrderComponent),
          children: [
            {
              path: `summary`,
              loadComponent: () => import('./routes/dashboard/routes/orders/routes/order/order-inner/order-inner.component').then(m => m.OrderInnerComponent),
            },
            {
              path: `products`,
              loadComponent: () => import('./routes/dashboard/routes/orders/routes//order/order-products/order-products.component').then(m => m.OrderProductsComponent),
            },
            {
              path: `technicians`,
              loadComponent: () => import('./routes/dashboard/routes/users/routes/technicians/technicians.component').then(m => m.TechniciansComponent),
            },
            {
              path: `corrections`,
              loadComponent: () => import('./routes/dashboard/routes/orders/routes/order/order-corrections/order-corrections.component').then(m => m.OrderCorrectionsComponent),
            },
            {
              path: `delivery`,
              loadComponent: () => import('./routes/dashboard/routes/orders/routes/order/order-delivery/order-delivery.component').then(m => m.OrderDeliveryComponent),
            },
            {
              path: `products/:serviceId`,
              loadComponent: () => import('./routes/dashboard/routes/orders/routes//order/order-products/order-products.component').then(m => m.OrderProductsComponent),
            },
            {
              path: `products/:serviceId/forms`,
              loadComponent: () => import('./routes/dashboard/routes/orders/routes//order/order-products/order-products.component').then(m => m.OrderProductsComponent),
            },
            {
              path: `products/:serviceId/forms/:formId`,
              loadComponent: () => import('./routes/dashboard/routes/orders/routes//order/order-products/order-product-form/order-product-form.component').then(m => m.OrderProductFormComponent),
            },
            {
              path: `status-history`,
              loadComponent: () => import('./routes/dashboard/routes/orders/routes/order/order-status-history/order-status-history.component').then(m => m.OrderStatusHistoryComponent),
            },
            {
              path: `chats`,
              loadComponent: () => import('./routes/dashboard/routes/chat/chat.component').then(m => m.ChatComponent),
            },
            {
              path: `patients`,
              loadComponent: () => import('./routes/dashboard/routes/users/routes/patients/patients.component').then(m => m.PatientsComponent),
            }
          ]
        },
      {
        path: pages.account,
        loadComponent: () => import('./routes/dashboard/routes/account/account.component').then(m => m.AccountComponent),
        children: [
          {
            path: 'summary',
            loadComponent: () => import('./routes/dashboard/routes/account/account-summary/account-summary.component').then(m => m.AccountSummaryComponent),
          }
        ]
      },
      {
        path: pages.chats,
        loadComponent: () => import('./routes/dashboard/routes/chat/chat.component').then(m => m.ChatComponent),
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ]
  },

  {
    path: `${pages.auth}`,
    loadComponent: () => import('./routes/auth/auth.component').then(m => m.AuthComponent),
    children: [
      {
        path: AuthRoutes.login,
        loadComponent: () => import('./routes/auth/login/login.component').then(m => m.LoginComponent),
        canActivate: [notLoggedInChild],
        data: {
          breadcrumbs: true
        }
      },
      {
        path: `${AuthRoutes.register}/:role`,
        loadComponent: () => import('./routes/auth/register/register.component').then(m => m.RegisterComponent),
        canActivate: [notLoggedInChild],
      },
      {
        path: AuthRoutes.authError,
        loadComponent: () => import('./routes/auth/auth-error/auth-error.component').then(m => m.AuthErrorComponent),
      },
      {
        path: `${AuthRoutes.authSuccess}/:role/:email`,
        loadComponent: () => import('./routes/auth/auth-success/auth-success.component').then(m => m.AuthSuccessComponent),
      },
      {
        path: `${AuthRoutes.verifyEmail}/:token`,
        loadComponent: () => import('./routes/auth/email-verification/email-verification.component').then(m => m.EmailVerificationComponent),
      },
      {
        path: `${AuthRoutes.emailConfirmation}/:email`,
        loadComponent: () => import('./routes/auth/verify-email/verify-email.component').then(m => m.VerifyEmailComponent),
      },
      {
        path: `${AuthRoutes.resetPassword}/:resetCode`,
        loadComponent: () => import('./routes/auth/reset-password/reset-password.component').then(m => m.ResetPasswordComponent),
      },
      {
        path: `${AuthRoutes.forgotPassword}`,
        loadComponent: () => import('./routes/auth/forgot-psw/forgot-psw.component').then(m => m.ForgotPswComponent),
      },
    ]
  },
  {
    path: pages.notFound,
    loadComponent: () => import('./routes/not-found/not-found.component').then(m => m.NotFoundComponent),
  },
  {
    path: '**',
    loadComponent: () => import('./routes/not-found/not-found.component').then(m => m.NotFoundComponent),
  }
];
