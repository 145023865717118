import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';

@Component({
  selector: 'app-switch-control',
  standalone: true,
  imports: [InputSwitchModule, ReactiveFormsModule],
  templateUrl: './switch-control.component.html',
  styleUrl: './switch-control.component.scss'
})
export class SwitchControlComponent {
  @Input() label!: string;
  @Input() control!: FormControl | any;
  @Input() name: string = '';
  @Input() classess!: string;
  @Input() secondary!: boolean;
  @Input() darkBorders!: boolean;
  @Input() small: boolean;

}
